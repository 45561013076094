.ps-sidebar-container {
  background-color: #f5f1eb !important;
}
.ps-menu-button {
  background-color: #f5f1eb;
  padding-left: 0 !important;
  color: #011e38 !important;
  font-weight: 500 !important;
}

.ps-menu-button:hover {
  background-color: #a98862 !important;
  color: #ffffff !important;
}
.ps-submenu-root {
  background-color: cadetblue !important;
}
.ps-menu-label {
  margin-left: 20px;
}
.ps-open {
  margin-left: 0 !important;
}
.ps-submenu-expand-icon {
  display: flex;
  align-items: center;
}

 